import Container from "react-bootstrap/Container";
import React from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ProfilePagePrintCv from "./profile-page-printcv";

function ProfilePage() {
  const [user, setUser] = React.useState({
    email: "",
    _id: "",
    newId: "",
    cv: [],
    fname: "",
    lname: "",
    description: "",
    location: "",
    availability: false,
    avatar_img: ""
  });
  const [loading, setLoading] = React.useState(true);
  const { id } = useParams();
  const [message, setMessage] = React.useState("Indlæser profil..");

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`/api/userprofile/${id}`);
        if (!response.ok) {
          const message = `Der er sket en teknisk fejl. Prøv igen senere.`;
          window.alert(message);
          return;
        } else if (response.ok) {
          const userHolder = await response.json();
          setUser(userHolder);
          if (userHolder.newId) {
            setLoading(false);
          } else if (!userHolder.newId) {
            setMessage("Det ser ud til, at du er gået forkert!");
          }
        }
      } catch (error) {
        console.error("Profilen kunne ikke indlæses pga. en teknisk fejl", error);
      }
    }

    fetchData();
  }, [id]);

  return (
    <div class="background-grey pb-5 pt-5">
      {!user._id ? (
        <>
          <Container className="div-height-90-vh">
            <div class="row ">
              <div class="col-8">
                <h3 className="mt-5">{message}</h3>
              </div>
            </div>
          </Container>
        </>
      ) : (
        <>
          <Container className="p-3 div-height-90-vh lowBoxShadowNoBackground-hundredWidth-noHeight background-white">
            <div class="row ">
              <div class="col-12 col-md-3">
              <div class=" profile-feed-card mb-3">
                  <div class="relative-class-holder">
                    <img
                      class="profile-imagine-profile"
                      src={user.avatar_img}
                      alt="..."
                    ></img>
                  </div>
                </div>
                <div class="row">
                <div class="col-lg-1"></div>
                  <div class="col-lg-10">
                    <div class="lowBoxShadowNoBackground-hundredWidth-noHeight background-white">
                      {/* {user.availability ? (
                        <>
                          {" "}
                          <div className="p-3 ">
                          <h5 class="mb-3">Åben for opgaver</h5>
                           
                            <p class="mt-2 text-low-padding-bot">
                              Du kan specificere opgaven i næste trin
                            </p>
                          </div>
                          <center></center>{" "}
                          <a href={`/filmarbejdere/${user.newId}/kontakt`}>
                          <button className="ms-2 mb-3 contact-profile mt-0">
                            Anmod om at hyre
                          </button></a>
                        </>
                      ) : (
                        <>
                          <div className="p-3">
                            <h5 class="mb-3">
                              {user.fname} er ikke åben for opgaver
                            </h5>
                          </div>
                        </>
                      )} */}
                    </div>
                  </div>
                  <div class="col-lg-1"></div>
                  </div>
                </div>
              <div class="col-12 col-md-9 ">
                <div className="underBorderThin mb-5">
                <h1 className="">
                  {user.fname} {user.lname}
                </h1>
                <p class="lead black-text">
                  {user.skills[0] ? " " + user.skills[0] : ""}
                  {user.skills[1] ? " | " + user.skills[1] : ""}
                  {user.skills[2] ? " | " + user.skills[2] : ""}
                </p>
                <p>  <img src={require('../../../images/icons8-location-50.png')} width="30" height="30" alt="Location icon" className="me-1" />{user.location}</p>
                </div>
             
              

                <div class="row mb-5 mt-4">
                  <div class="col border-profile-text ">
                    <div className="underBorderThin">
                    <div class="mb-1">
                      <h4 class="">Om mig</h4>
                    </div>

                    <p style={{ whiteSpace: "pre-line" }}>{user.description}</p>
                    </div>
                  
                  
                  </div>
                </div>
                <div class="row mb-3 mt-3">
                  <h4>Erfaringer </h4>
                </div>
                <div className="mb-5">

                
                <div className="underborder-black-small mb-3">
                  <div class="row">
                    <div class="col-4">
                      <p className=" text-no-padding-bot">Produktion</p>
                    </div>
              
                    <div class="col-4 ">
                      <p className=" text-no-padding-bot">Rolle</p>
                    </div>
                    <div class="col-2">
                      <p className=" text-no-padding-bot">År</p>
                    </div>
                    <div class="col-2 ">
                      <p className=" text-no-padding-bot">Link</p>
                    </div>
                  </div>
                </div>
                {<ProfilePagePrintCv cv={[user.cv]} />}
                </div>
              </div>
            </div>

       
          </Container>
        </>
      )}
    </div>
  );
}

export default ProfilePage;
