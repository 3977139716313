import React from "react";
import "./App.css";
import NavBar from "./components/navbar";
import FrontPage from "./components/frontpage";
import Footer from "./components/footer";
import { Routes, Route} from "react-router-dom";
import ProductionsFeed from "./components/productionComponents/productions-feed";
import ProfilesFeed from "./components/profilesfeed";
import ProfilePage from "./components/profileComponents/profile-page";
import ProfileSignup from "./components/profilesignup";
import ProfileLogin from "./components/profilelogin";
import ProfileEdit from "./components/profileedit";
import ProfileEditCv from "./components/profileeditcv";
import ProfileEditInfo from "./components/profileeditinfo";
import ProfileEditImage from "./components/profileeditimage";
import ProfileContact from "./components/profilecontact/profilecontact";
import VerifyEmail from "./components/verifyemail";
import Messages from "./components/messages";
import ProductionPost from "./components/productionComponents/production-post";
import TemporaryLanding from "./components/temporarylanding/temporarylanding";
import DeleteMailList from "./components/deletemaillist";
import ProductionPage from "./components/productionComponents/production-page";
import ProjectApply from "./components/projectapply/projectapply";
import ProfileProductions from "./components/profileComponents/profile-productions/profile-productions";

function App() {
  return (
    <div className="App">
      <div class="div-height-hundred-vh">
     {/* <NavBar/>  */}
      <Routes>
          <Route path="/" element={<TemporaryLanding />} /> 

          <Route path="/produktioner" element={<ProductionsFeed />} /> 
          <Route path="/produktioner/opret" element={<ProductionPost />} />
          <Route path="/produktioner/:id" element={<ProductionPage />} />
          <Route path="/login" element={<ProfileLogin/>} />
          <Route path="/rediger-profil/produktioner/" element={<ProfileProductions />} />
          <Route path="/filmarbejdere/opret" element={<ProfileSignup />} />
          <Route path="/filmarbejdere/:id" element={<ProfilePage />} /> 
          <Route path="/rediger-profil" element={<ProfileEdit/>} />
          <Route path="/rediger-profil/profil-billede" element={<ProfileEditImage/>} />
          {/* 
          
          <Route path="/rediger-profil/produktioner/" element={<ProfileProductions />} />
          <Route path="/login" element={<ProfileLogin/>} />
          <Route path="/produktioner/:id/kontakt" element={<ProjectApply />} />
       
          <Route path="/rediger-profil/erfaringer" element={<ProfileEditCv/>} />
         
           <Route path="/opret-profil" element={<ProfileSignup />} />
           <Route path="/filmarbejdere/:id/kontakt" element={<ProfileContact />} />
          <Route path="/rediger-profil/info" element={<ProfileEditInfo/>} />
        
          <Route path="/bekræft-profil/:token" element={<VerifyEmail />} />
          <Route path="/beskeder" element={<Messages />} />  */}
          {/* <Route path="/login" element={<ProfileLogin/>} />
     
 
           */}


          {/* <Route path="/filmarbejdere" element={<ProfilesFeed />} /> */}




     {/* <Route path="/slet-mailliste-tilmelding/:email/:secret" element={<DeleteMailList />} />*/}
     


           {/*
        */}
      </Routes>
      </div>
        <Footer /> 
         </div>
  );
}
export default App;
