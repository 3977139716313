import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Form } from "react-bootstrap";
import Roles from "../../functions/roles";
import ProductionsFeedData from "./productions-feed-data";
import axios from "axios";

function ProductionsFeed() {
  let params = new URL(document.location).searchParams;
  const [userData, setUserData] = useState(null);
  const [skills, setSkills] = useState("");
  const [location, setLocation] = useState("");
  const [data, setData] = useState(null);
  const [query, setQuery] = useState({
    skills: params.get('skills') || "",
    location: params.get('location') || "",
    page: params.get('page') || 1,
  });

  let totalPages = 0;
  if (data) {
    totalPages = Math.ceil(data.totalDocuments / 9);
  }

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <span key={i}>
          <button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setQuery({ ...query, page: i });
            }}
            className={`button-read-more m-2 ${query.page == i || (!query.page && i === 1) ? "button-pagination-active m-2" : ""}`}
          >
            {i}
          </button>
        </span>
      );
    }
    return buttons;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_HOST}/api/checkuser`, { withCredentials: true });

        if (response.data.status) {
          setUserData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (query.skills) {
      setSkills(query.skills);
    } else if (!query.skills) {
      setSkills("");
    }
  }, [query.skills]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams();
    updateUrlParams(query);
    if (window.location.search && window.location.search.length > 1) {
      fetch(`${process.env.REACT_APP_HOST}/api/productions${window.location.search}`)
        .then((res) => res.json())
        .then((data) => setData(data));
    } else {
      fetch(`${process.env.REACT_APP_HOST}/api/productions`)
        .then((res) => res.json())
        .then((data) => setData(data));
    }
  }, [query]);

  const updateUrlParams = (query) => {
    const searchParams = new URLSearchParams();

    if (query.role) searchParams.set("role", query.role);
    if (query.location) searchParams.set("location", query.location);
    if (query.availability) searchParams.set("availability", query.availability);
    if (query.skills) searchParams.set("skills", query.skills);
    if (query.page) searchParams.set("page", query.page);

    const queryString = searchParams.toString();
    const newUrl = window.location.pathname + (queryString ? `?${queryString}` : "");
    window.history.replaceState({}, '', newUrl);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const updatedQuery = {
      ...query,
      skills,
      location,
      page: 1,
    };
    setQuery(updatedQuery);
    updateUrlParams(updatedQuery);
  };

  return (
    <div className="pb-5 background-grey div-height-90-vh">
      <Container>
        <div className="row underborder-black">
          <div className="col-8">
            <h1 className="mt-5">Produktioner</h1>
            <p className="lead">Oversigt over filmproduktioner, der søger crews.</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-9">
            <Form onSubmit={handleFormSubmit}>
              <div className="row mb-5">
                <div className="col">
                  <Form.Select
                    id="skills"
                    defaultValue={query.skills}
                    onChange={(e) => setSkills(e.target.value)}
                  >
                    <option value="">Alle roller</option>
                    <Roles plural={true} />
                  </Form.Select>
                </div>
                <div className="col">
                  <Form.Select
                    id="location"
                    defaultValue={query.location}
                    onChange={(e) => setLocation(e.target.value)}
                  >
                    <option value="">Hele Danmark</option>
                    <option value="Hovedstaden">Hovedstaden</option>
                    <option value="Sjælland">Sjælland</option>
                    <option value="Syddanmark">Syddanmark</option>
                    <option value="Midtjylland">Midtjylland</option>
                    <option value="Nordjylland">Nordjylland</option>
                  </Form.Select>
                </div>
                <div className="col">
                  <button type="submit" className="main-search-frontpage">
                    Søg
                  </button>
                </div>
              </div>
            </Form>
            <div className="row mt-2">
              <ProductionsFeedData data={data} />
            </div>
            <div className="row pagination-div">
              <p className="mt-1">
                {query.page > 1 && (
                  <button
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      setQuery({ ...query, page: query.page - 1 });
                    }}
                    className="button-read-more m-2"
                  >
                    {"<"}
                  </button>
                )}
                {renderPaginationButtons()}
                {query.page !== totalPages && totalPages > 1 && (
                  <button
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      setQuery({ ...query, page: parseInt(query.page) + 1 });
                    }}
                    className="button-read-more m-2"
                  >
                    {">"}
                  </button>
                )}
              </p>
            </div>
          </div>
          
          <div className="col-12 col-md-3 mt-5">
            <div className="lowBoxShadowNoBackground-hundredWidth-noHeight-sidebar p-3 mb-3 background-white top-border">
              <h5 className="mb-3">Tilføj produktion</h5>
              {userData ? (
                <>
              <p>Søger du crewmedlemmer til din produktion? Lav et opslag her</p>
              <a href={`/produktioner/opret`}><button className="sidebar-button">Opret produktion</button></a>
              </>
              ) : (
                <>
              <p>Søger du crewmedlemmer til din produktion? Opret en profil for at lave et opslag</p>
              <a href={`/filmarbejdere/opret`}><button className="sidebar-button">Opret profil</button></a>
              </>
                 )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ProductionsFeed;
