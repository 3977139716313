import React from "react";
import Container from "react-bootstrap/Container";

function ProductionsFeedData({ data }) {
  return (
    <Container>
      <div className="row mb-4">
        {!data ? (
          <p>Indlæser produktioner...</p>
        ) : (
          <>
            {data.results.map((production) => (
              <div className="row pt-3 mb-4 lowBoxShadowNoBackground-hundredWidth-noHeight background-white bottom-border" key={production.productionid}>
                <h4>{production.productionTitle}</h4>
                <p className="p-no-padding">
                  {production.categories.map((category, index) => (
                    <span key={index} className="badge blue-ish-background me-1">{category}</span>
                  ))}
                  {production.paid && <span className="badge green-background">En eller flere betalte roller</span>}
                </p>
                <p className="p-no-padding mt-3"><b>Beskrivelse</b><br />{production.productionDescription}</p>
                <p className="p-no-padding mt-3 mb-2"><b>Ledige positioner</b></p>
                <div className="row">
                  {production.positions.map((position) => (
                    <div key={position.id} className="col-12 col-md-4 card m-2 p-1 role-box-project">
                      <a href={`/produktioner/${production.productionid}#${position.id}`} className="role-box-project-link">
                        {position.role ? position.role : position.skills}
                      </a>
                    </div>
                  ))}
                </div>
                <div className="row pt-3">
                  <p className="p-no-padding mt-3"><b>Location og ansøgningsfrist</b></p>
                  <p className="p-no-padding mt-1">
                    <img src={require('../../../images/icons8-location-50.png')} width="30" height="30" alt="Location icon" className="me-1" />
                    {production.location}
                  </p>
                  <div className="col-8 pt-1">
                    <p className="p-no-padding">
                      <img src={require('../../../images/icons8-time-50.png')} width="30" height="30" alt="Time icon" className="me-1"/> 
                      Ansøgningsfrist: {new Date(production.deadline).toLocaleDateString()}.
                    </p>
                  </div>
                  <div className="col-4">
                    <p className="mt-1">
                      <a href={`/produktioner/${production.productionid}`}>
                        <button className="project-feed-view-more float-end mb-3">Læs mere</button>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </Container>
  );
}

export default ProductionsFeedData;
