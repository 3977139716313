import React, { useEffect, useState } from "react";
import Roles from "../../functions/roles";

function ProfilePagePrintCv(cv) {
  const [userData, setUserData] = useState(null);
  const singularRoles = Roles({ plural: false });
  let arrayCv = cv.cv;
  let flatArrayCv = arrayCv.flat(1);

  function PrintExperiences() {

    if (cv.cv && cv.cv.length > 0 && !flatArrayCv.length == 0) {
      let sortedExperiences = flatArrayCv.sort((p1, p2) =>
        p1.year < p2.year ? 1 : p1.year > p2.year ? -1 : 0
      );
      let sortedExperiencesNew = sortedExperiences.flat(1);
      return sortedExperiencesNew.map((cvItem, index) => {
        return (
          <div key={index}>
            <div key={cvItem.id}>
              <div class="row">
                <div class="col-4">
                  {" "}
                  <p>{cvItem.production }</p>
                </div>
           
                <div class="col-4">
                  <p>{cvItem.role}</p>
                </div>
                <div class="col-2">
                  {" "}
                  <p>{cvItem.year}</p>
                </div>
                <div class="col-2">
                  {cvItem.link ? (
                    <p>
                      <a href={cvItem.link} target="_blank" className="profile-cv-link">
                        Link
                      </a>
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
                {/* <p key={cvItem.id}>Film: {cvItem.production}. Rolle: {cvItem.role}. År: {cvItem.year}. <a href={cvItem.link} target="_blank">Se mere</a> </p> */}
              </div>{" "}
            </div>
          </div>
        );
      });
    } else {
      return (
  
        <p>Profilen har ikke tilføjet nogen erfaringer</p>
      )
      
    }
  }

  return (
    <>
<PrintExperiences/>
    </>
  );
}


export default ProfilePagePrintCv;
