import React, { useEffect, useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";



function ProfileEditImage() {
  const [userData, setUserData] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState({});
  const [message, setMessage] = useState("");
  const handleSelectFile = (e) => setFile(e.target.files[0]);

  const handleUpload = async () => {
    try {
      setLoading(true);
      const data = new FormData();
      data.append("my_file", file);
      const res = await axios.post("/api/uploadimage", data);
      setRes(res.data);
      console.log("res.secure_url: " + res.secure_url)
      console.log("res.data.secure_url: " + res.data.secure_url)
      await axios.put(
        `/api/updateavatar/${userData.id.toString()}`,
        {
          avatar_img: res.data.secure_url
        }
      );
    } catch (error) {
      setMessage("Hov, noget gik galt. Prøv igen.");
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginClick = () => {
    window.location.href = "/login";
  };


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          "/api/returnuser",
          {
            withCredentials: true,
          }
        );

        if (response.data.status) {
          setUserData(response.data);
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);


  return (
    <div class="background-grey full-height ">
      <Container>
        <div class="pt-5">
          {userData ? (
            <div className="row">
              <div className="col-lg-8 mb-5">
              <div class="row underborder-black mb-4 ">
              <h1>Rediger profil</h1>
              <p>{userData.fname} {userData.lname}</p>
              <p>Rediger profil / Skift profilbillede </p>
              </div>
                {/* <h3>Skift profilbillede</h3> */}
     

                {/* <form onSubmit={handleUpload}> */}
                <label htmlFor="file" >
        {" "}
        Vælg et nyt profilbillede
      </label>
      {file && <center> {file.name}</center>}
      <input
        id="file"
        type="file"
        className="signup-image-box ms-2"
        onChange={handleSelectFile}
        multiple={false}
      />
        <code>
        {Object.keys(res).length > 0
          ? Object.keys(res).map((key) => (
              <p className="output-item" key={key}>
                <span>{key}:</span>
                <span>
                  {typeof res[key] === "object" ? "object" : res[key]}
                </span>
                <p>meeen: {res.secure_url}</p>
              </p>
            ))
          : null}
      </code>
      {file && (
        <>
          <button onClick={handleUpload} className="btn-green">
            {loading ? "Opdater dit billede..." : "Opdater profilbillede"}
          </button>
        </>
      )}
                  {/* <button type="submit" className="button-search mb-5">
                    Opdater profilbillede
                  </button>
                </form> */}
                {message && <p>{message}</p>}
              </div>
              <div>
                <div className="pb-3">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = "/rediger-profil";
                    }}
                    className="mb-5 me-1 button-back"
                  >
                    Tilbage til rediger profil
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = `/filmarbejdere/${userData.newId}`;
                    }}
                    className="mb-5 button-back"
                  >
                    Se din profil
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <p>Du skal være logget ind for at tilgå denne side.</p>
              <button onClick={handleLoginClick} className="logout-button">
                Login her
              </button>
            </>
          )}
        </div>
      </Container>
    </div>
  );
}

export default ProfileEditImage;
