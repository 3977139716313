import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';


function ProfileEdit() {
  const [userData, setUserData] = useState(null);
  const [availability, setAvailability] = useState(false);


  const handleLoginClick = () => {
    window.location.href = '/login';
  };

  const handleSubmit = async (newAvailability) => {
    try {
      const response = await axios.put(
        `/api/updatestatus/${userData.id.toString()}`,
        {
          availability: newAvailability,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        window.location.href = "/rediger-profil/";
      } else {
        console.log("Hov, noget gik galt. Prøv igen.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/api/returnuser', {
          withCredentials: true,
        });

        if (response.data.status) {
          setUserData(response.data); 
          setAvailability(response.data.availability)
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData(); 
  }, []);

  console.log(availability)

  return (
    <div class="background-grey full-height ">
    <Container>
    <div class="pt-5">
      {userData ? (
        <div>
          <div class="row underborder-black mb-4 "> 
          <h1>Rediger profil</h1>
          <p>{userData.fname} {userData.lname}</p>
          </div>

          {/* <div class="row">
            <div class="col-md-4">
          <form onSubmit={handleSubmit}>

          <div className="form-group mb-4">
                      <label htmlFor="selectAvailability">Din status</label>
                      <select
                        className="form-control"
                        id="selectAvailability"
                        value={availability}
                        onChange={(event) => {
                          const newAvailability = event.target.value === 'true';
                          setAvailability(newAvailability);
                          handleSubmit(newAvailability); 
                        }}
                        required
                      >
                        <option value="false" >Ikke åben for opgaver</option>
                        <option value="true">Åben for opgaver</option>
                    
                      </select>
                    </div>
          </form></div>
          <div class="col-md-4">
            <p></p>
       </div>
          <div class="col-md-4">
            <p></p>
    </div>
          </div> */}
          <div class="row">

          <div class="col-md-4 mb-2">
            <div class="edit-profile-boxes ">
          <h5>Din info</h5>
          
          <p>Roller, område, beskrivelse, osv.</p>
          <a href={`/rediger-profil/info`}>
                          <button className="edit-profile-boxes-button float-end me-1 mb-1 mt-1">
                          Rediger informationer
                          </button>
                        </a> </div></div>

                        <div class="col-md-4 mb-2">
                        <div class="edit-profile-boxes ">
          <h5>Erfaringer/CV</h5>
          <p>Fremhæv dine erfaringer</p>
          <a href={`/rediger-profil/erfaringer`}>
                          <button className="edit-profile-boxes-button float-end me-1 mb-1 mt-1">
                          Rediger erfaringer
                          </button>
                        </a>
        
          <p></p>
          </div></div>

          <div class="col-md-4 mb-2">
          <div class="edit-profile-boxes ">
          <h5>Profilbillede</h5>
          <p>Skift dit profilbillede</p>
          <a href={`/rediger-profil/profil-billede`}>
                          <button className="edit-profile-boxes-button float-end me-1 mb-1 mt-1">
                          Skift profilbillede
                          </button>
                        </a></div></div>
        </div>
        </div>
        
      ) : (
        <>
        <p>Du skal være logget ind for at tilgå denne side.</p>
        <button onClick={handleLoginClick} className="logout-button">Login her</button>
        </>
      )}
         <div class="row mt-5">
       </div>
      <div class="row mt-5">
      <p><i>Ønsker du at slette din profil? Send en mail til kontakt@filmcrew.dk med "Slet profil" i emnefeltet, så slettes den hurtigst muligt.</i></p>
    </div>
    </div>
    
    </Container>
    </div>
  );
}

export default ProfileEdit;
