import React from 'react';
import Container from "react-bootstrap/Container";

function Footer() {
  return (
    <Container fluid id="footer">
        <Container>
            <br></br>
        <p class="pt-3">Filmcrew.dk</p>
        <p>For henvendelser skriv til kontakt@filmcrew.dk</p>
        <br></br>
        <br></br>
        </Container>
    </Container>
  );
}

export default Footer;
