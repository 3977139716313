import Container from "react-bootstrap/Container";
import React, { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function ProductionPage() {
  const [user, setUserData] = React.useState(null);
  const { skillsid } = useParams();

  const [submitState, setSubmitState] = React.useState({
    submitStateBol: false,
    positionid: "",
  });
  const [project, setProject] = React.useState({
    productionOwnerId: "",
    productionTitle: "",
    productionDescription: "",
    location: "",
    deadline: "",
    positions: [],
    categories: [],
    positionsCounter: "",
    paid: false,
    productionid: "",
  });
  const [formObject, setFormObject] = React.useState({
    senderFname: "",
    senderLname: "",
    senderMail: "",
    senderPhone: "",
    applicationMessage: "",
  });

  const [loading, setLoading] = React.useState(true);
  const { id } = useParams();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [message, setMessage] = React.useState("Indlæser produktion..");
  const [submitSuccess, setSubmitSuccess] = React.useState(false);

  useEffect(() => {
    // Extract the fragment identifier (part after #)
    const fragment = window.location.hash.substring(1);

    if (fragment) {
      // Delay the scroll action to ensure the element is available in the DOM
      setTimeout(() => {
        const element = document.getElementById(fragment);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 1000); // Adjust the delay as needed
    }
  }, [id]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/returnuser", {
          withCredentials: true,
        });

        if (response.data.status) {
          setUserData(response.data);
        } else {
          console.error("User data fetch failed");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);

  const openSubmitBox = (positionid) => {
    setSubmitState({
      submitStateBol: true,
      positionid: positionid,
    });
  };

  const handleSubmit = async (event, positionid) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST}/api/productions/apply/${id}/position/${positionid}`,
        {
          email: user.email,
          fname: user.fname,
          lname: user.lname,
          phone: formObject.senderPhone,
          applicationMessage: formObject.applicationMessage,
          applierId: user.id,
          avatar_img: user.avatar_img,
          userid: user.newId,
        }
      );
      if (response.data.success) {
        setSubmitSuccess(true);
        setErrorMessage("");
      } else {
        setErrorMessage("Hov, der gik noget galt. Prøv igen.");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Hov, der gik noget galt. Prøv igen.");
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`/api/productions/${id}`);
        if (!response.ok) {
          const message = `Der er sket en teknisk fejl. Prøv igen senere.`;
          window.alert(message);
          return;
        } else {
          const productionHolder = await response.json();
          setProject(productionHolder);
          if (productionHolder.productionid) {
            setLoading(false);
          } else {
            setMessage("Det ser ud til, at du er gået forkert!");
          }
        }
      } catch (error) {
        console.error(
          "Produktionen kunne ikke indlæses pga. en teknisk fejl",
          error
        );
      }
    }

    fetchData();
  }, [id]);

  return (
    <div className="pt-5 background-grey pb-5">
      {loading ? (
        <Container className="div-height-90-vh">
          <div className="row underborder-black">
            <div className="col-8">
              <h3 className="mt-5">{message}</h3>
            </div>
          </div>
        </Container>
      ) : (
        <Container className="p-3 div-height-90-vh lowBoxShadowNoBackground-hundredWidth-noHeight background-white bottom-border">
          <div className="row">
            <p>
              <a href="/produktioner" className="production-breadcrump">
                Produktioner
              </a>{" "}
              /{" "}
              <a
                href={`/produktioner/${id}`}
                className="production-breadcrump-copy"
              >
                {project.productionTitle}
              </a>
            </p>
            <div className="col-12 col-md-8">
              <h1 className="">{project.productionTitle}</h1>
              <p className="p-no-padding">
                {project.categories.map((category, index) => (
                  <span key={index} className="badge blue-ish-background me-1">
                    {category}
                  </span>
                ))}
                {project.paid && (
                  <span className="badge green-background">
                    En eller flere betalte roller
                  </span>
                )}
              </p>

              <h4 className="mt-5 ">Beskrivelse </h4>
              <p>
                {project.productionDescription}
              </p>
            </div>
            <div className="col-12 col-md-4 ">
              <div className="allroundborder-black-small p-2 top-border">
                    <p className="p-no-padding mt-1"><b>Location og ansøgningsfrist</b></p>
                  <p className="p-no-padding mt-1">
                    <img src={require('../../../images/icons8-location-50.png')} width="25" height="25" alt="Location icon" className="me-1" />
                    {project.location}
                  </p>
                  <div className="col-8 pt-1">
                    <p className="p-no-padding">
                      <img src={require('../../../images/icons8-time-50.png')} width="25" height="25" alt="Time icon" className="me-1"/> 
                      Frist: {new Date(project.deadline).toLocaleDateString()}
                    </p>
                  </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-8 mt-5">
              <div className="row mb-2">
                <h4>Rolleopslag</h4>
              </div>
              <div className=" mb-3">
                <div className="row"></div>
              </div>
              {project.positions.length > 0 ? (
                project.positions.map((position, index) => {
                  const userApplication = position.applications
                    ? position.applications.find(
                        (app) => user && app.applierId === user.id
                      )
                    : null;

                  return (
                    <div className="row" key={index} id={position.id}>
                      <div className="col-10 ">
                        <div className="col-12 ">
                          <p className="lead">
                            <b>{position.skills}</b>
                          </p>
                          <p className="">
                            {position.jobDescription}
                          </p>
                        </div>

                        {submitState.submitStateBol === true &&
                        submitState.positionid === position.id ? (
                          <>
                            {user ? (
                              <>
                                {/* Render previous applications */}
                                {userApplication && (
                                  <div className="mb-3">
                                    <p>
                                      <b className="mb-2">Beskeder</b>{" "}
                                      {userApplication.applicationMessages &&
                                      userApplication.applicationMessages.length >
                                        0 ? (
                                        userApplication.applicationMessages.map(
                                          (message, msgIndex) => (
                                            <div
                                              key={msgIndex}
                                              className="underBorderThin mb-3"
                                            >
                                              <p><b>{userApplication.fname} {userApplication.lname} </b>
                                                {new Date(
                                                  message.date
                                                ).toLocaleDateString()}
                                              </p>
                                              <p>{message.message}</p>
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <p>Ingen beskeder endnu.</p>
                                      )}
                                    </p>
                                  </div>
                                )}

                                {submitSuccess && submitState.positionid === position.id ? (
                                  <p>
                                    <b>Din ansøgning er sendt!</b>
                                  </p>
                                ) : (
                                  <div className="allroundborder-black-small mb-4 top-border">
                                    <p className="p-2">
                                      Din filmcrew.dk-profil og email vedhæftes
                                      automatisk ansøgningen.
                                    </p>
                                    <form
                                      onSubmit={(event) =>
                                        handleSubmit(event, position.id)
                                      }
                                      className="p-2"
                                    >
                                      <div className="row">
                                        {!userApplication && (
                                          <div className="col-12 mb-3">
                                            <input
                                              required
                                              type="text"
                                              placeholder="Telefon"
                                              onChange={(e) =>
                                                setFormObject({
                                                  ...formObject,
                                                  senderPhone: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        )}
                                        <div className="col-12">
                                          <textarea
                                            required
                                            placeholder="Besked"
                                            onChange={(e) =>
                                              setFormObject({
                                                ...formObject,
                                                applicationMessage:
                                                  e.target.value,
                                              })
                                            }
                                            rows={6}
                                            cols={40}
                                          />
                                        </div>
                                        <div className="col-12">
                                          <button
                                            type="submit"
                                            className="project-feed-view-more float-end mb-3"
                                          >
                                            Send ansøgning
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                )}
                              </>
                            ) : (
                              <p>Du skal være logget ind for at søge rollen</p>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="row">
                              <div className="row">
                                <div className="col-9">
                                  <p className="p-no-padding">
                                    {position.paid && (
                                      <span className="badge green-background">
                                        Betalt rolle
                                      </span>
                                    )}
                                  </p>
                                </div>
                                <div className="col-3">
                                  <button
                                    className="project-feed-view-more float-end mb-3"
                                    onClick={() => openSubmitBox(position.id)}
                                  >
                                    Søg rollen
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-2"></div>
                        <div className="col-8 div-50-width underBorder mt-2 mb-5">
                          <div className="col-2"></div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>Ingen opslag for denne produktion.</p>
              )}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}

export default ProductionPage;