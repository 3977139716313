import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, Button } from 'react-bootstrap';
import Roles from "../../functions/roles";
import { nanoid } from 'nanoid';

function ProductionPost() {
  const [productionTitle, setProductionTitle] = useState('');
  const [productionDescription, setProductionDescription] = useState('');
  const [message, setMessage] = useState('');
  const [location, setLocation] = useState('');
  const [deadline, setDeadline] = useState('');
  const [positions, setPositions] = useState([]);
  const [positionHolder, setPositionHolder] = useState({ id: '', skills: '', jobDescription: '', paid: false, applications: []  });
  const [editIndex, setEditIndex] = useState(null); 
  const [userData, setUserData] = useState(null);
  const [somePaid, setSomePaid] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const editBoxRef = useRef(null); 

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_HOST}/api/checkuser`, { withCredentials: true });

        if (response.data.status) {
          setUserData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    setSomePaid(positions.some(position => position.paid));
  }, [positions]);

  const formatCategory = (category) => {
    return category.charAt(0).toUpperCase() + category.slice(1).toLowerCase();
  };

  const formatProductionTitle = (title) => {
    return title.charAt(0).toUpperCase() + title.slice(1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!productionTitle || !productionDescription || !location || !deadline) {
      setMessage('Alle felter undtagen kategori og rolle/position er påkrævet.');
      return;
    }

    if (categories.length === 0) {
      setMessage('Du skal tilføje mindst én kategori.');
      return;
    }

    if (positions.length === 0) {
      setMessage('Du skal tilføje mindst én rolle/position.');
      return;
    }

    const formattedProductionTitle = formatProductionTitle(productionTitle);
    const productionid = nanoid(10);
    const formData = {
      productionTitle: formattedProductionTitle,
      productionDescription,
      location,
      deadline,
      positions,
      categories,
      productionid,
      productionOwnerId: userData.id,
      somePaid
    };
console.log(formData)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST}/api/createproduction`,
        formData,
        { withCredentials: true }
      );

      if (response.data.success) {
      
           setProductionTitle('');
           setProductionDescription('');
           setLocation('');
           setDeadline('');
           setPositions([]);
           setCategories([]);
           setPositionHolder({ id: '', skills: '', jobDescription: '', paid: false });
           setMessage('');
           setNewCategory('');

           window.location.href = `/produktioner/${productionid}`;
      } else {
        setMessage('Hov, noget gik galt. Prøv igen.');
      }
    } catch (error) {
      console.error(error);
      setMessage('Der opstod en fejl under indsendelsen. Prøv igen senere.');
    }
  };

  const submitPosition = () => {
    if (positionHolder.skills && positionHolder.jobDescription) {
      if (editIndex !== null) {
        const updatedPositions = [...positions];
        updatedPositions[editIndex] = positionHolder;
        setPositions(updatedPositions);
        setEditIndex(null); 
      } else {
        setPositions([...positions, { ...positionHolder, id: nanoid(10) }]);
      }
      setPositionHolder({ id: '', skills: '', jobDescription: '', paid: false });
    }
  };

  const removePosition = (id) => {
    const updatedPositions = positions.filter(pos => pos.id !== id);
    setPositions(updatedPositions);
  };

  const editPosition = (id) => {
    const index = positions.findIndex(pos => pos.id === id);
    if (index !== -1) {
      setPositionHolder(positions[index]);
      setEditIndex(index);
      editBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleAddCategory = (event) => {
    event.preventDefault(); // Prevent default form submission
    const formattedCategory = formatCategory(newCategory);
    
    if (formattedCategory.trim() && !categories.includes(formattedCategory) && !formattedCategory.includes(' ')) {
      setCategories([...categories, formattedCategory]);
      setNewCategory('');
    }
  };

  const handleCategoryChange = (e) => setNewCategory(e.target.value);

  // Determine if we need to add `required` attributes based on positions array length
  const isPositionRequired = positions.length === 0;

  return (
    <div>
      <Container>
        {userData ? (
          <>
            <div className="row underborder-black mb-4">
              <div className="col-md-12">
                <h1 className="mt-5">Opret produktion</h1>
                <p className="lead">
                  Opret et opslag her og find crewmedlemmer. Efter oprettelse vises dit projekt på listen over produktioner.
                </p>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-md-6 div-height-hundred-vh">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-1">
                    <label htmlFor="productionTitle">Overskrift</label>
                    <input
                      type="text"
                      className="form-control"
                      id="productionTitle"
                      value={productionTitle}
                      onChange={(event) => setProductionTitle(event.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mb-1">
                    <label htmlFor="productionDescription">Beskrivelse af produktionen</label>
                    <textarea
                      className="form-control"
                      id="productionDescription"
                      value={productionDescription}
                      onChange={(event) => setProductionDescription(event.target.value)}
                      rows={4}
                      cols={60}
                      required
                    />
                  </div>
                  <div className="form-group mb-1">
                    <label htmlFor="location">Primære område for produktionen</label>
                    <select
                      className="form-select"
                      id="location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      required
                    >
                      <option value="" disabled>Vælg et område</option>
                      <option value="Hovedstaden">Hovedstaden</option>
                      <option value="Sjælland">Sjælland</option>
                      <option value="Syddanmark">Syddanmark</option>
                      <option value="Midtjylland">Midtjylland</option>
                      <option value="Nordjylland">Nordjylland</option>
                      <option value="Ikke specificeret">Flere områder - specificer evt. i beskrivelsen</option>
                    </select>
                  </div>
                  <div className="form-group mb-1">
                    <label htmlFor="deadline">Ansøgningsfrist</label>
                    <input
                      type="date"
                      className="form-control"
                      id="deadline"
                      value={deadline}
                      onChange={(event) => setDeadline(event.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="categories">Kategorier</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        className="form-control me-2"
                        placeholder="F.eks. genre eller filmtype. Tilføj én ad gangen"
                        id="categories"
                        value={newCategory}
                        onChange={handleCategoryChange}
                      />
                      <button 
                        type="button" // Ensure this is a button, not submit
                        className="div-add-position-production-form-button float-end"
                        onClick={handleAddCategory}
                      >
                        Tilføj
                      </button>
                    </div>
                    <div className="mt-2">
                      {categories.map((category, index) => (
                        <span key={index} className="badge bg-secondary me-2">{category}</span>
                      ))}
                    </div>
                  </div>

                  <div className="mb-4">
                    {positions.map((pos) => (
                      <div key={pos.id}>
                        <p><strong>Rolle:</strong> {pos.skills}</p>
                        <p><strong>Beskrivelse:</strong> {pos.jobDescription}</p>
                        <p><strong>Betalt:</strong> {pos.paid ? 'Ja' : 'Nej'}</p>
                        <button type="button" className="div-add-position-production-form-button me-2" onClick={() => removePosition(pos.id)}>Fjern</button>
                        <button type="button"  className="div-add-position-production-form-button ms-2"  onClick={() => editPosition(pos.id)}>Rediger</button>
                      </div>
                    ))}
                  </div>

                  <div id="positions">
                    <div className="div-add-position-production-form pb-5" ref={editBoxRef}>
                      {editIndex !== null ? <p className='mb-2'><b>Opdater position</b></p> : <p className='mb-2'><b>Tilføj ledig position</b></p>}
                      <div className="form-group mb-2">
                        <label htmlFor="skills">Rolle</label>
                        <select
                          className="form-select"
                          id="skills"
                          value={positionHolder.skills}
                          onChange={(e) => setPositionHolder({ ...positionHolder, skills: e.target.value })}
                          required={isPositionRequired} // Conditionally apply required
                        >
                          <option value="" disabled>Vælg en rolle</option>
                          <Roles plural={false} />
                        </select>
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="jobDescription">Beskrivelse af opgaven</label>
                        <textarea
                          className="form-control"
                          id="jobDescription"
                          value={positionHolder.jobDescription}
                          onChange={(event) => setPositionHolder({ ...positionHolder, jobDescription: event.target.value })}
                          rows={3}
                          cols={60}
                          required={isPositionRequired} // Conditionally apply required
                        />
                      </div>
                      <div className="form-group mb-4">
                        <label htmlFor="selectAvailability">Betalt arbejde</label>
                        <select
                          className="form-control"
                          id="selectAvailability"
                          value={positionHolder.paid}
                          onChange={(event) => {
                            const newPaid = event.target.value === 'true';
                            setPositionHolder({ ...positionHolder, paid: newPaid });
                          }}
                          required={isPositionRequired} // Conditionally apply required
                        >
                          <option value="false">Ikke betalt</option>
                          <option value="true">Betalt</option>
                        </select>
                      </div>

                      <button 
                        type="button" 
                        onClick={submitPosition} 
                        className="div-add-position-production-form-button float-end"
                      >
                        {editIndex !== null ? 'Opdater position' : 'Tilføj position'}
                      </button>
                    </div>
                    {isPositionRequired && <p className="small">Du skal tilføje mindst én rolle/position for at kunne oprette produktionen.</p>}
                  </div>

                  <button type="submit" className="button-search mt-4">Opret produktion</button>

                  {message && <p className="text-danger">{message}</p>}
                </form>
              </div>
            </div>
    
          </>
        ) : (
          <p className="mt-5">Du skal være logget ind for at oprette en produktion</p>
        )}
      </Container>
    </div>
  );
}

export default ProductionPost;