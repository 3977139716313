import Container from "react-bootstrap/Container";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

import { customAlphabet } from 'nanoid'; 
const nanoid = customAlphabet('1234567890abcdefghijklmnopwzy', 8)
const smallnanoid = customAlphabet('123456789abcdefghijklmnopqwz', 10)

const recaptchaRef = React.createRef();
const secret = nanoid();

function TemporaryLanding() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = recaptchaRef.current.getValue();
    if (!token) {
      setMessage("Vær venlig at udfylde reCAPTCHA.");
      return;
    }
    recaptchaRef.current.reset();

    setLoadingResponse(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_HOST}/api/maillistsignup`, { email, token, secret });
      setMessage(response.data.message);
      if (response.data.success) {
        setSuccess(true); // Update state if registration is successful
      }
    } catch (error) {
      console.error("There was an error!", error);
      setMessage("Noget gik galt. Prøv igen.");
    } finally {
      setLoadingResponse(false);
    }
  };

  return (
    <div className="background-temporary d-flex">
      <Container className="my-auto">
        <Row>
          <Col className="col-12 col-md-8 col-lg-7">
            <h1 className="display-6 white-text">Filmcrew.dk lanceres snart</h1>
            <p className="lead white-text">
              Filmcrew.dk lanceres snart med det simple formål at gøre det mere overskueligt at samle film crews til produktioner i Danmark. Siden vil bestå af to opslagstavler: en hvor man kan slå ledige roller på produktioner op, og en hvor man kan fremvise sin profil og blive hyret til produktioner.
            </p>
            <div>
              <p className="white-text">
                Arbejder du med film? Tilmeld dig vores mailliste og vær blandt de første til at få besked, når siden går live. Du modtager blot en enkelt mail ved lancering.
              </p>
              {!success && (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Indtast din email her"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <button className="main-hero-button mb-2" type="submit" disabled={loadingResponse}>
                    {loadingResponse ? "Du tilmeldes..." : "Tilmeld mailliste"}
                  </button>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={recaptchaRef}
                  />
                </form>
              )}
              {message && <p className="white-text mt-1 success-mail-signup">{message}</p>}
              {/* {success && <p className="white-text mt-3">Tak for din tilmelding! Du vil modtage en mail, når vi går i luften.</p>} */}
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
}

export default TemporaryLanding;
