import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import axios from 'axios';

const ProfileProductions = () => {
  const [user, setUser] = useState(null);
  const [productions, setProductions] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showApplications, setShowApplications] = useState({});
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [expandedApplicationId, setExpandedApplicationId] = useState(null);
  const [deleteConfirmations, setDeleteConfirmations] = useState({});

  const showFullApplication = (applicationId) => {
    setExpandedApplicationId(prevId => (prevId === applicationId ? null : applicationId));
  };

  const showMore = (posid) => {
    setShowApplications((prevState) => ({
      ...prevState,
      [posid]: !prevState[posid],
    }));
  };

  const showFullDescription = (prodId) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [prodId]: !prevState[prodId],
    }));
  };

  const handleCheckboxChange = (productionId) => {
    setDeleteConfirmations((prevState) => ({
      ...prevState,
      [productionId]: !prevState[productionId],
    }));
  };

  const deleteProduction = async (productionId) => {
    try {
      const response = await axios.delete('/api/deleteproduction', {
        data: { productionOwnerId: user.id, productionId }
      });
      if (response.data.success) {
        setProductions(productions.filter(production => production.productionid !== productionId));
      } else {
        setMessage(response.data.message);
        setError(true);
      }
    } catch (error) {
      console.error('Error deleting production:', error);
      setMessage('Der skete en fejl.');
      setError(true);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`/api/checkuser`);
        console.log("Check User Response:", response.data);
        if (!response.data.status) {
          setMessage(`Der er sket en teknisk fejl. Prøv igen senere.`);
          setError(true);
          setLoading(false);
          return;
        } else if (response.data.status) {
          const userHolder = response.data;
          setUser(userHolder);

          if (userHolder.id) {
            try {
              const productionsResponse = await axios.get(`/api/profilesproductions/${userHolder.id}`);
              setProductions(productionsResponse.data.results);
              setLoading(false);
            } catch (error) { 
              console.error('Error fetching productions:', error);
              setMessage(`Der er sket en teknisk fejl. Prøv igen senere.`);
              setError(true);
              setLoading(false);
            }
          } else {
            setMessage("Det ser ud til, at du er gået forkert!");
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Profilen kunne ikke indlæses pga. en teknisk fejl", error);
        setMessage(`Der er sket en teknisk fejl. Prøv igen senere.`);
        setError(true);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="div-height-hundred-vh">
        <Container className="div-height-hundred-vh">
          <div className="row mb-3">
            <div className="col-8">
              <h1 className="mt-5">Indlæser...</h1>
            </div>
          </div>
        </Container>
      </div>
    );
  }

  if (error) {
    return <div>
      <Container className="div-height-hundred-vh">
      {message}
      <p>Det kan muligvis skyldes, at du ikke er logget ind.</p>
      </Container>
     </div>;
  }

  return (
    <div className="pb-5">
      <Container className="div-height-hundred-vh ">
        <div className="row mb-3">
          <div className="col-8">
            <h1 className="mt-5">Dine produktioner</h1>
          </div>
        </div>
        {productions.length === 0 ? (
          <p>Du har ingen aktive produktioner.</p>
        ) : (
          productions.map((production) => (
            <div key={production.productionid} className='mb-5 allroundborder-black-small p-3'>
              <h2 className='mb-3'>{production.productionTitle}</h2>
              <div className="mb-3">
                <p className="p-no-padding mt-1"><b>Location og ansøgningsfrist</b></p>
                <p className="p-no-padding mt-1">
                  <img src={require('../../../images/icons8-location-50.png')} width="25" height="25" alt="Location icon" className="me-1" />
                  {production.location}
                </p>
                <div className="col-8 pt-1">
                  <p className="p-no-padding">
                    <img src={require('../../../images/icons8-time-50.png')} width="25" height="25" alt="Time icon" className="me-1"/> 
                    Frist: {new Date(production.deadline).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <p>
                <b>Beskrivelse: </b>
                {expandedDescriptions[production.productionid] 
                  ? production.productionDescription 
                  : `${production.productionDescription.substring(0, 200)}...`}
                <button 
                  className="div-add-position-production-form-button mb-2 ms-2" 
                  onClick={() => showFullDescription(production.productionid)}
                >
                  {expandedDescriptions[production.productionid] ? 'Vis mindre' : 'Vis mere'}
                </button>
              </p>
              <h4 className='mb-3'>Rolleopslag</h4>
              {production.positions.map((position) => (
                <div key={position.id} className="mb-2 row">
                  <div className='col-12 col-md-6'>
                    <p className='lead'><b>{position.skills}</b></p>
                    <p>Opgavebeskrivelse: {position.jobDescription}</p>
                    <p>{position.paid ? 'Betalt opgave' : 'Ikke betalt opgave'}</p>
                    <p>Antal ansøgere: {position.applications ? position.applications.length : 0}</p>
                    <button className="div-add-position-production-form-button mb-3" onClick={() => showMore(position.id)}>
                      {showApplications[position.id] ? 'Skjul ansøgninger' : 'Se ansøgninger'}
                    </button>
                    {showApplications[position.id] && (
                      <div className="applications">
                        {position.applications && position.applications.length > 0 ? (
                          position.applications.map((application, appIndex) => (
                            <div key={appIndex} className="application mb-2 allroundborder-black-small p-2">
                              <div className='row'>
                                <div className='col-4'>
                                  <img
                                    className="profile-imagine-profile profile-picture-application"
                                    src={application.avatar_img}
                                    alt="Profile"
                                  />
                                </div>
                                <div className='col-8'>
                                  <p><a href={`/filmarbejdere/${application.userid}`} target="_blank" className='profile-productions-profile-link'>{application.fname} {application.lname}</a> </p>
                                  <p>Telefon: {application.phone}</p>
                                  <p>Mail: {application.email}</p>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-8'>
                                </div>
                                <div className='col-4'>
                                  <button 
                                    className="div-add-position-production-form-button mb-2 ms-2" 
                                    onClick={() => showFullApplication(application.applicationId)}
                                  >
                                    {expandedApplicationId === application.applicationId ? 'Skjul ansøgning' : 'Vis ansøgning'}
                                  </button>
                                </div>
                              </div>
                              {expandedApplicationId === application.applicationId && (
                                <div className='applicationObject'>
                                  <p><b>Beskeder</b></p>
                                  {application.applicationMessages && application.applicationMessages.length > 0 ? (
                                    application.applicationMessages.map((msg, index) => (
                                      <div key={index} className="mb-2">
                                        <p><b>{application.fname} {application.lname} </b>
                                          {new Date(msg.date).toLocaleDateString()}
                                        </p>
                                        <p>{msg.message}</p>
                                      </div>
                                    ))
                                  ) : (
                                    <p>Ingen beskeder endnu.</p>
                                  )}
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <p>Ingen ansøgninger endnu.</p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8 div-50-width underBorder mt-2 mb-5">
                      <div className="col-2"></div>
                    </div>
                  </div>
                </div>
              ))}
              <div>
                <button 
                  className="button-message-read-more-decline mb-1 mt-5" 
                  onClick={() => deleteProduction(production.productionid)}
                  disabled={!deleteConfirmations[production.productionid]}
                >
                  Slet opslag
                </button>
                <div className="form-check">
                  <input 
                    type="checkbox" 
                    className="form-check-input" 
                    id={`deleteConfirm-${production.productionid}`} 
                    checked={deleteConfirmations[production.productionid] || false} 
                    onChange={() => handleCheckboxChange(production.productionid)} 
                  />
                  <label className="form-check-label" htmlFor={`deleteConfirm-${production.productionid}`}>
                    Jeg forstår, at opslaget ikke kan gendannes efterfølgende
                  </label>
                </div>
              </div>
            </div>
          ))
        )}
      </Container>
    </div>
  );
};

export default ProfileProductions;